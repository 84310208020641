.shadow-left {
  box-shadow: -8px 0 15px -3px rgba(29, 29, 29, 0.7);
}

.country-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
  border-radius: 1.5rem; /* Adjust the border-radius to match your design */
}

/* CustomScrollbar.css */
/* This is the custom CSS for the scrollbar */
.scrollbar-thumb {
  background-color: #1B592D; /* Indigo color for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollbar-track {
  background: transparent; /* Transparent track */
}

/* Apply these styles only to Webkit browsers like Chrome, Safari */
::-webkit-scrollbar-thumb {
  background-color: #EF4422;
  border-radius: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}
